<template>
  <v-dialog
    :value="value"
    persistent
    :max-width="400"
  >
    <v-card>
      <v-card-title>que opasa</v-card-title>
      <v-card-text>
        Coloque el mouse en el ícono de inicio y obtendrá una información sobre herramientas casi en el
        centro del diálogo.
      </v-card-text>
      <v-card-actions>
        <v-tooltip
          min-width="400px"
          z-index="250"
        >
          <span></span>
        </v-tooltip>

        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          flat
          @click="value = false;"
        >
          Cancel
        </v-btn>
        </v-icon>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: ['value'],

  data() {
    return {

    }
  },
}
</script>
