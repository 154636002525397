<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>
      <v-container fluid>
        <v-form
          ref="form"
          v-model="formValid"
          lazy-validation
        >
          <v-row
            justify="center"
          >
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="Name"
                :append-icon="icons.mdiAccountBoxOutline "
                :rules="[Validaciones.requerido]"
                label="Nombre"
                dense
                required
                hide-details="true"
                outlined
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="SurName"
                :rules="[Validaciones.requerido]"
                label="Apellido"
                dense
                hide-details="true"
                outlined
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <v-checkbox
                v-model="Holder"
                label="Titular"
                hide-details
                hide-spin-buttons
                dense
                :append-icon="icons.mdiBriefcaseAccountOutline "
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row
            justify="center"
          >
          </v-row>
          <v-row
            justify="center"
          >
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="Phone"
                :append-icon="icons.mdiCellphone "
                :rules="[Validaciones.requerido, Validaciones.telefono]"
                required
                type="number"
                label="Teléfono"
                dense
                hide-details="true"
                outlined
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="Email"
                :append-icon="icons.mdiAt"
                :rules="[Validaciones.emailRules]"
                label="Correo Electrónico"
                dense
                required
                hide-details="true"
                outlined
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="Dni"
                :append-icon="icons.mdiCardAccountDetailsOutline "
                :rules="[Validaciones.requerido, Validaciones.dni]"
                label="D.N.I."
                type="number"
                hide-spin-buttons
                dense
                required
                hide-details="true"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row
            justify="center"
          >
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="SexoSeleccionado"
                :items="Sex"
                item-value="idSex"
                item-text="descripcionSexo"
                :append-icon="icons.mdiHumanMaleFemale "
                label="Sexo"
                dense
                :rules="[Validaciones.requerido]"
                outlined
                hide-details="true"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="JobActivity"
                :append-icon="icons.mdiBriefcaseOutline"
                :rules="[Validaciones.requerido]"
                required
                label="Actividad Laboral"
                dense
                hide-details="true"
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="TipoDeudaSeleccionado"
                :items="TipoDeuda"
                :rules="[Validaciones.requerido]"
                item-value="descripcion"
                item-text="descripcion"
                :append-icon="icons.mdiFinance "
                hide-details
                label="Situación Crediticia"
                dense
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-row
            justify="start"
          >
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="contactName"
                :append-icon="icons.mdiCardAccountDetailsOutline "
                :rules="[Validaciones.requerido]"
                label="Nombre Referente"
                type="text"
                hide-spin-buttons
                dense
                required
                hide-details="true"
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="contactNumber"
                :append-icon="icons.mdiCardAccountDetailsOutline "
                :rules="[Validaciones.requerido, Validaciones.telefono]"
                label="Telefono Referente"
                type="number"
                hide-spin-buttons
                dense
                required
                hide-details="true"
                outlined
              ></v-text-field>
            </v-col>
            <!-- <v-col
              cols="12"
              md="4"
            >
              <Crop-image-vuetify
                etiqueta="Foto Cliente"
                @updateobjectUrl="fotoTitular"
              />
            </v-col> -->
          </v-row>

          <v-row
            justify="center"
          >
            <v-expansion-panels flat>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span style="float: left">
                    <v-icon>
                      {{ icons.mdiHomeFloor1 }}
                    </v-icon>
                    Domicilio Particular
                  </span>
                  <template v-slot:actions>
                    <v-icon>
                      {{ icons.mdiChevronDown }}
                    </v-icon>
                  </template>
                  <v-spacer></v-spacer>
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      class="text--secondary"
                    >
                      <v-fade-transition leave-absolute>
                        <v-row

                          style="width: 100%"
                        >
                          <v-col cols="12">
                            {{ Street + ' ' + Floor + ' ' + Dpto + ' ' + Neighborhood + ' ' + City || '' }}
                          </v-col>
                        </v-row>
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                  <v-spacer></v-spacer>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-row
                    justify="center"
                  >
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="Street"
                        :append-icon="icons.mdiHomeOutline "
                        :rules="[Validaciones.requerido]"
                        required
                        label="Calle y/o Número - Mzna/Lote"
                        dense
                        hide-details="true"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="Floor"
                        :append-icon="'mdi-briefcase'"
                        label="Piso"
                        dense
                        hide-details="true"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="Dpto"
                        :append-icon="'mdi-briefcase'"
                        label="Dpto."
                        dense
                        hide-details="true"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row
                    justify="center"
                  >
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="Neighborhood"
                        :append-icon="icons.mdiHomeGroup"
                        :rules="[Validaciones.requerido]"
                        name="input-10-1"
                        label="Barrio"
                        dense
                        hide-details="true"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="City"
                        :append-icon="icons.mdiCityVariantOutline "
                        :rules="[Validaciones.requerido]"
                        label="Ciudad"
                        dense
                        required
                        hide-details="true"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="4"
                    >
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span style="float: left">
                    <v-icon>
                      {{ icons.mdiHomeFloor2 }}
                    </v-icon>
                    Domicilio Comercial
                  </span>
                  <v-spacer></v-spacer>
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      class="text--secondary"
                    >
                      <v-fade-transition leave-absolute>
                        <v-row

                          no-gutters
                          style="width: 100%"
                        >
                          <v-col cols="12">
                            {{ ComercialStreet + ' ' + ComercialFloor + ' ' + ComercialDpto + ' ' + ComercialNeighborhood + ' ' + ComercialCity || '' }}
                          </v-col>
                        </v-row>
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                  <template v-slot:actions>
                    <v-icon>
                      {{ icons.mdiChevronDown }}
                    </v-icon>
                  </template>
                  <v-spacer></v-spacer>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row justify="center">
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-switch
                        v-model="repetirDomicilio"
                        label="Mismo Domicilio Particular"
                        hide-details
                        :append-icon="icons.mdiHomeFloor1"
                        dense
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row
                    justify="center"
                  >
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="ComercialStreet"
                        :append-icon="icons.mdiHomeOutline "
                        :rules="[Validaciones.requerido]"
                        required
                        label="Calle y/o Número - Mzna/Lote"
                        dense
                        hide-details="true"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="ComercialFloor"
                        :append-icon="'mdi-briefcase'"
                        label="Piso"
                        dense
                        hide-details="true"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="ComercialDpto"
                        :append-icon="'mdi-briefcase'"
                        label="Dpto."
                        dense
                        hide-details="true"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row
                    justify="start"
                  >
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="ComercialNeighborhood"
                        :append-icon="icons.mdiHomeGroup"
                        :rules="[Validaciones.requerido]"
                        name="input-10-1"
                        label="Barrio"
                        dense
                        hide-details="true"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="ComercialCity"
                        :append-icon="icons.mdiCityVariantOutline "
                        :rules="[Validaciones.requerido]"
                        label="Ciudad"
                        dense
                        required
                        hide-details="true"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col
                      cols="12"
                      md="4"
                    >
                      <v-checkbox
                        v-model="gpslocation"
                        label="Registrar actual Ubicación"
                        hide-details
                        hide-spin-buttons
                        dense
                        :append-icon="icons.mdiMapMarker"
                      ></v-checkbox>
                    </v-col> -->
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
          <!-- <v-row
            justify="center"
          >
            <v-col
              cols="12"
              md="4"
            >
              <Crop-image-vuetify etiqueta="Foto DNI Dorso" />
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <Crop-image-vuetify etiqueta="Foto Boleta de Impuesto" />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <Crop-image-vuetify
                etiqueta="Foto Documentacion Titular"
                @updateobjectUrl="fotoTitular"
              />
            </v-col>
          </v-row> -->
        </v-form>
      </v-container>
      <v-card-actions>
        <v-row class="mt-7 mb-3">
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            depressed
            @click="close()"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="guardaClienteNuevo"
            color="primary"
            :loading="isSaving"
            depressed
            @click="optionSave()"
          >
            Guardar
          </v-btn>
          <v-btn
            v-else
            color="primary"
            :loading="isSaving"
            depressed
            @click="ActualizarCliente()"
          >
            Actualizar
          </v-btn>
          <v-spacer></v-spacer>
        </v-row>
      </v-card-actions>
      <v-snackbar
        v-model="Showsnackbar"
        top
      >
        {{ mensajeSnack }}
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            @click="Showsnackbar = false"
          >
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
    <dialogVeryfyDNI
      ref="dialogExisteDNI"
      :mensaje="mensajeDialog"
      @confirmar="ActiveClient()"
    >
    </dialogVeryfyDNI>
  </v-container>
</template>
<script>
import axios from 'axios'

import {
  mdiEyeOutline, mdiCityVariantOutline, mdiShieldAccountOutline,
  mdiCakeVariantOutline, mdiHomeOutline, mdiHomeGroup, mdiMapMarker,
  mdiCardAccountDetailsOutline, mdiCellphone, mdiAt, mdiAccountBoxOutline, mdiHumanMaleFemale,
  mdiFileDocumentCheck, mdiCardAccountDetails, mdiBriefcaseOutline, mdiFinance,
  mdiBriefcaseAccountOutline, mdiCardAccountMailOutline, mdiHomeFloor1, mdiHomeFloor2, mdiChevronDown,
} from '@mdi/js'
// eslint-disable-next-line import/extensions
import ModalContainer from '../../components/ModalContainer'
// eslint-disable-next-line import/extensions
// import CropImage from '../../components/CropImage'
// eslint-disable-next-line import/extensions
// import CropImageVuetify from '../../components/CropImageVuetify'
import dialogVeryfyDNI from '../../components/base/DialogGlobal.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ModalContainer,
    // eslint-disable-next-line vue/no-unused-components
    dialogVeryfyDNI,
    // eslint-disable-next-line vue/no-unused-components
    // CropImage,
    // eslint-disable-next-line vue/no-unused-components
    // CropImageVuetify,
  },
  data() {
    return {
      TipoDeuda: [{
        id: 1,
        descripcion: 'Situación normal',
      },
      {
        id: 2,
        descripcion: 'Atrasado',
      },
      {
        id: 3,
        descripcion: 'Con seguimiento especial',
      },
      {
        id: 4,
        descripcion: 'Con alto riesgo de insolvencia',
      },
      {
        id: 5,
        descripcion: 'Cancelado se renueva',
      },
      {
        id: 5,
        descripcion: 'Cancelado no se renueva',
      },
      ],
      icons: {
        mdiChevronDown,
        mdiCityVariantOutline,
        mdiShieldAccountOutline,
        mdiHomeOutline,
        mdiHumanMaleFemale,
        mdiHomeGroup,
        mdiEyeOutline,
        mdiCardAccountDetailsOutline,
        mdiCellphone,
        mdiAt,
        mdiAccountBoxOutline,
        mdiCakeVariantOutline,
        mdiFileDocumentCheck,
        mdiCardAccountDetails,
        mdiBriefcaseOutline,
        mdiFinance,
        mdiBriefcaseAccountOutline,
        mdiCardAccountMailOutline,
        mdiHomeFloor1,
        mdiHomeFloor2,
        mdiMapMarker,

      },
      mensajeDialog: '',
      loadingButton: false,
      mensajeSnack: '',
      Showsnackbar: false,
      colorSnack: 'success',
      guardaClienteNuevo: true,
      Sex: [{
        idSex: 'F',
        descripcionSexo: 'Femenino',
      },
      {
        idSex: 'M',
        descripcionSexo: 'Masculino',
      },
      ],
      contactNumber: null,
      contactName: null,
      Holder: true,
      gpslocation: false,
      repetirDomicilio: false,
      TipoDeudaSeleccionado: '',
      SexoSeleccionado: null,
      TitularDeComercio: null,
      DocumentSeleccionado: [],
      activePicker: null,
      Name: '',
      SurName: '',
      Street: '',
      Floor: '',
      Dpto: '',
      City: '',
      lat: 0,
      lon: 0,
      Neighborhood: '',
      ComercialStreet: '',
      ComercialFloor: '',
      ComercialDpto: '',
      ComercialCity: '',
      ComercialNeighborhood: '',
      Phone: '',
      Email: '',
      Dni: '',
      JobActivity: '',
      menu: false,
      dialog: false,
      formValid: null,
      isSaving: false,
      contratistaSeleccionado: null,
      tipoModal: 0,
      clienteParaEditar: null,
      dniExists: false,
      Validaciones: {
        emailRules:
        v => {
          if (v.length > 0) {
            return /.+@+.+.com+/.test(v) ? true : 'E-mail debe ser valido'
          }

          return true
        },
        requerido: v => !!v || 'Campo Requerido',
        dni: v => (v.length >= 7 && v.length <= 8) || 'DNI debe ser 7 u 8 digitos',
        min: v => v.length >= 6 || 'Minimo 6 caracteres',
        telefono: v => (/(3)\d{9}/.test(v) && v.length === 10 ? true : 'Celular debe ser de 10 digitos sin 0 ni 15'),
      },
    }
  },
  computed: {
    formTitle() {
      return this.$route.params.id > 0
        ? `Editar Cliente Nro.: ${this.$route.params.id}`
        : 'Registrar Cliente'
    },
  },
  watch: {
    repetirDomicilio() {
      if (this.repetirDomicilio === true) {
        this.ComercialStreet = this.Street
        this.ComercialFloor = this.Floor
        this.ComercialDpto = this.Dpto
        this.ComercialCity = this.City
        this.ComercialNeighborhood = this.Neighborhood
      }
    },
    gpslocation() {
    },
    menu(val) {
      // eslint-disable-next-line no-unused-expressions, no-return-assign
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },
  mounted() {
    if (this.$route.params.id) { this.getClientByID(this.$route.params.id) }
    this.getLocationData()
  },
  methods: {

    async getLocationData() {
      if (!('geolocation' in navigator)) {
        this.lat = '0'
        this.lon = '0'

        return alert(
          'Tu navegador no soporta el acceso a la ubicación. Intenta con otro',
        )
      }

      const onUbicacionConcedida = loc => {
        const location = loc.coords

        this.lat = location.latitude
        this.lon = location.longitude
      }
      const onErrorDeUbicacion = err => {
        console.log(`Error obteniendo ubicación: ${err.message}`)
        console.log(`Error obteniendo ubicación: ${err.message}`)
        console.log('Error obteniendo ubicación: ', err)
        this.lat = '0'
        this.lon = '0'
      }

      const optionRequest = {
        enableHighAccuracy: true, // Alta precisión
        maximumAge: 0, // No queremos caché
        timeout: 5000, // Esperar 5 segundos
      }

      navigator.geolocation.getCurrentPosition(
        onUbicacionConcedida,
        onErrorDeUbicacion,
        optionRequest,
      )
    },
    fotoTitular(e) {
      console.log(e)
    },
    optionSave() {
      if (this.$route.params.id > 0) {
        // console.log('Cliente Actualizado')
      } else {
        this.VerifyDNI()

      //  this.InsertClient()
      }
    },
    close() {
      this.$router.push('/abmclient')
    },
    submitForm() {
      this.dialog = false
      this.$refs.form.reset()
      this.$emit('Recargar')
    },

    orderList() {
      this.list = this.list.sort((one, two) => one.order - two.order)
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element
      const draggedElement = draggedContext.element

      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      )
    },
    async editClient(tipoid) {
      await this.$router.replace({ name: 'registerclient', params: { id: tipoid } })
      location.reload()

      // this.$router.go(0)
    },
    async getClientByID(personId) {
      this.guardaClienteNuevo = false
      this.url = `${this.$store.getters.urlBase}/api/Client/clientById`
      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
        params: { idPerson: personId },
        url: this.url,
      }
      const respuesta = await axios(this.url, options)
      if (respuesta.status === 200) {
        this.clienteParaEditar = respuesta.data
        this.SexoSeleccionado = this.clienteParaEditar.sex ? this.clienteParaEditar.sex : null
        this.Name = this.clienteParaEditar.name
        this.SurName = this.clienteParaEditar.surname
        this.Phone = this.clienteParaEditar.phone ? this.clienteParaEditar.phone.toString() : null
        this.Email = this.clienteParaEditar.email ? this.clienteParaEditar.email : ''
        this.Dni = this.clienteParaEditar.dni.toString()
        this.contactName = this.clienteParaEditar.contactName ? this.clienteParaEditar.contactName : ''
        this.contactNumber = this.clienteParaEditar.contactNumber ? this.clienteParaEditar.contactNumber.toString() : ''
        this.JobActivity = this.clienteParaEditar.jobActivity ? this.clienteParaEditar.jobActivity : ''
        this.TipoDeudaSeleccionado = this.clienteParaEditar.state
        this.Street = this.clienteParaEditar.personalAddress.street ? this.clienteParaEditar.personalAddress.street : ''
        this.Floor = this.clienteParaEditar.personalAddress.floor ? this.clienteParaEditar.personalAddress.floor : ''
        this.Dpto = this.clienteParaEditar.personalAddress.dpto ? this.clienteParaEditar.personalAddress.dpto : ''
        this.City = this.clienteParaEditar.personalAddress.city ? this.clienteParaEditar.personalAddress.city : ''
        this.Neighborhood = this.clienteParaEditar.personalAddress.neighborhood ? this.clienteParaEditar.personalAddress.neighborhood : ''

        //  this.ComercialCity = this.clienteParaEditar.commercialAddress.ComercialCity

        this.ComercialStreet = this.clienteParaEditar.commercialAddress.street ? this.clienteParaEditar.commercialAddress.street : ''
        this.ComercialNeighborhood = this.clienteParaEditar.commercialAddress.neighborhood ? this.clienteParaEditar.commercialAddress.neighborhood : ''
        this.ComercialFloor = this.clienteParaEditar.commercialAddress.floor ? this.clienteParaEditar.commercialAddress.floor : ''
        this.ComercialDpto = this.clienteParaEditar.commercialAddress.dpto ? this.clienteParaEditar.commercialAddress.dpto : ''
        this.ComercialCity = this.clienteParaEditar.commercialAddress.city ? this.clienteParaEditar.commercialAddress.city : ''
      } else {
        this.$store.dispatch('showSnack', {
          text: 'Error al actualizar los datos',
          color: 'danger',
          timeout: 2500,
        })
        this.isSaving = false
      }
    },
    async ActualizarCliente() {
      if (this.$refs.form.validate()) {
        this.isSaving = true
        this.url = `${this.$store.getters.urlBase}/api/Client/update`
        const params = {
          Holder: this.Holder,
          JobActivity: this.JobActivity,
          State: this.TipoDeudaSeleccionado,
          ContactName: this.contactName,
          ContactNumber: this.contactNumber.toString(),
          Person: {
            loggedPersonId: this.$store.getters.usuario.user_personid,
            personId: this.$route.params.id,
            dni: this.Dni.toString(),
            name: this.Name,
            surName: this.SurName,
            sex: this.SexoSeleccionado,
            phone: this.Phone.toString(),
            email: this.Email,
            address: {
              street: this.Street,
              city: this.City,
              neighborhood: this.Neighborhood,
              floor: this.Floor,
              dpto: this.Dpto,
              mza: null,
              lote: null,
              
            },

          },
          Documentation: [],
          commercialAddress: {
            street: this.ComercialStreet,
            city: this.ComercialCity,
            neighborhood: this.ComercialNeighborhood,
            floor: this.ComercialFloor,
            dpto: this.ComercialDpto,
            mza: null,
            lote: null,
            
          },
        }

        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
            'content-type': 'application/json',
          },
        }
        await axios
          .post(this.url, params, config)
          .then(
            // eslint-disable-next-line no-return-assign
            response => {
              this.respuesta = response
              this.isSaving = true
              if (this.respuesta.status === 200) {
                this.$store.dispatch('showSnack', {
                  text: 'Actualizacion exitosa',
                  color: 'success',
                  timeout: 2500,
                })
                this.isSaving = false
                this.$router.push({ name: 'abmclient' })
              }
            },
          )
          .catch(e => {
            if (e.response.data) {
              this.mensajeSnack = e.response.data
            } else {
              this.mensajeSnack = 'Error de conexion'
            }
            this.isSaving = false
            this.$store.dispatch('showSnack', {
              text: this.mensajeSnack,
              color: 'error',
              timeout: 2500,
            })
          })
      }
    },
    async ReactivePerson() {
      this.url = `${this.$store.getters.urlBase}/api/Client/ReactivePerson`

      const params = {
        // eslint-disable-next-line radix
        dni: parseFloat(this.Dni),

      }

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
          'content-type': 'application/json',
        },
        params,
      }
      await axios
        .post(this.url, params, config)
        .then(
          // eslint-disable-next-line no-return-assign
          response => {
            this.respuesta = response
            this.$store.dispatch('showSnack', {
              text: 'Cuenta Reactivada Correctamente',
              color: 'success',
              timeout: 2500,
            })
            this.editClient(this.respuesta.data)
          },
        )
        .catch(e => {
          if (e.response) {
            this.mensajeSnack = e.response.data
          } else {
            this.mensajeSnack = 'Error de conexion'
          }
          this.$store.dispatch('showSnack', {
            text: this.mensajeSnack,
            color: 'error',
            timeout: 2500,

          })
        })
    },
    ActiveClient() {
      this.ReactivePerson()

    //  this.InsertClient()
    },
    VerifyDNI() {
      if (this.dniExists) {
        this.mensajeDialog = `El DNI Nro.: ${this.Dni} ya pertenece a un cliente que se encuentra desactivado, desea activarlo?`
        this.$refs.dialogExisteDNI.OpenDialog()
      } else {
        this.InsertClient()
      }
    },

    async InsertClient() {
      if (this.$refs.form.validate()) {
        this.isSaving = true
        this.url = `${this.$store.getters.urlBase}/api/Client`
        const params = {
          Holder: this.Holder,
          JobActivity: this.JobActivity,
          State: this.TipoDeudaSeleccionado,
          ContactName: this.contactName,
          ContactNumber: this.contactNumber.toString(),
          Person: {
            loggedPersonId: this.$store.getters.usuario.user_personid,
            dni: this.Dni.toString(),
            name: this.Name,
            surName: this.SurName,
            sex: this.SexoSeleccionado,
            phone: this.Phone.toString(),
            email: this.Email,
            address: {
              street: this.Street,
              city: this.City,
              neighborhood: this.Neighborhood,
              floor: this.Floor,
              dpto: this.Dpto,
              mza: null,
              lote: null,
              positionX: '0',
              positionY: '0',
            },

          },

          Documentation: [],
          commercialAddress: {
            street: this.ComercialStreet,
            city: this.ComercialCity,
            neighborhood: this.ComercialNeighborhood,
            floor: this.ComercialFloor,
            dpto: this.ComercialDpto,
            mza: null,
            lote: null,
            positionX: '0',
            positionY: '0',
          },
        }
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
            'content-type': 'application/json',
          },
        }
        await axios
          .post(this.url, params, config)
          .then(
            // eslint-disable-next-line no-return-assign
            response => {
              this.respuesta = response
              this.isSaving = false
              if (this.respuesta.status === 200) {
                this.$store.dispatch('showSnack', {
                  text: 'Creacion exitosa',
                  color: 'success',
                  timeout: 2500,
                })
                this.$router.push({ name: 'abmclient' })
              }
            },
          )
          .catch(e => {
            if (e.response) {
              if (e.response.data) {
                console.log(e.response)
                this.mensajeSnack = 'Ocurrio un error en la operacion'
              } else {
                this.mensajeSnack = 'Ocurrio un error en la operacion'
              }
            } else {
              console.log('Errorrr 03')
              this.mensajeSnack = 'Error de conexion'
            }
            if (this.mensajeSnack.includes('INACTIVO')) {
              this.mensajeDialog = `El DNI Nro.: ${this.Dni} ya pertenece a un cliente que se encuentra desactivado, desea activarlo?`
              this.$refs.dialogExisteDNI.OpenDialog()
            } else {
              this.$store.dispatch('showSnack', {
                text: this.mensajeSnack,
                color: 'error',
                timeout: 2500,
              })
            }

            this.isSaving = false
          })
      }
    },
  },
}
</script>
<style scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
